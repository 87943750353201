<template>
  <div class="row">
    <b-container fluid>
      <b-card>
        <b-tabs class="mx-1" @input="onTabChange">
          <b-form>
            <b-row>
              <b-col cols="12" md="4">
                <b-form-group label="Hệ đào tạo" label-for="trainingSystemId">
                  <v-select v-model="filter.trainingSystemId" :options="trainingSystems"
                    :reduce="option => option.value" @input="onTrainingSystemsChange" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4">
                <b-form-group label="Khóa học" label-for="courseId">
                  <v-select v-model="filter.courseId" :options="courses" :reduce="option => option.value"
                    @input="onCoursesChange" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" v-if="isShowGraduationReviewTab">
                <div class="text-right" style="padding-top: 20px;">
                  <b-button v-show="filter.courseId > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                    @click="getDataSourcesFromStore">Tìm kiếm
                  </b-button>
                  <b-button v-show="filter.courseId > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                    @click="onGraduationGrading">Xét tốt nghiệp
                  </b-button>
                </div>
              </b-col>
              <b-col cols="12" md="4" v-if="!isShowGraduationReviewTab">
                <b-form-group label="Khoa/Bộ môn" label-for="departmentId">
                  <v-select v-model="filter.departmentId" :options="departments" :reduce="option => option.value"
                    @input="onDepartmentChange" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" v-if="!isShowGraduationReviewTab">
                <b-form-group label="Ngành học" label-for="majorId">
                  <v-select v-model="filter.majorId" :options="majors" :reduce="option => option.value"
                    @input="onMajorChange" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" v-if="!isShowGraduationReviewTab">
                <b-form-group label="Lớp cố định" label-for="classId">
                  <v-select v-model="classId" :options="classes" :reduce="option => option.value" />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" v-if="!isShowGraduationReviewTab">
                <div class="text-right" style="padding-top: 20px;">
                  <b-button v-show="classId > 0" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                    @click="onSelect">Danh sách
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
          <b-tab title="Xét tốt nghiệp">
            <b-overlay :show="isLoading" rounded="sm">
              <div class="m-2">
                <b-row class="mt-2">
                  <b-col cols="12">
                    <div class="d-flex align-items-center mb-1 mt-0">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage - 1) + 1 }}
                        đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                    </div>
                    <vue-good-table mode="remote" :columns="columnsGraduationGrading" :rows="dataListsGraduationGrading"
                      :pagination-options="paginationOptions" :total-rows="totalRows" :line-numbers="true"
                      @on-page-change="onPageChange" @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange">
                      <div slot="emptystate" style="text-align: center; font-weight: bold">
                        Không có bản ghi nào !
                      </div>
                      <template slot="table-row" slot-scope="props">
                        <span>{{ props.formattedRow[props.column.field] }}</span>
                      </template>

                      <!-- pagination -->
                      <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                          <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage - 1) + 1 }}
                              đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                            </span>
                          </div>
                          <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap"> Hiển thị </span>
                            <b-form-select v-model="filter.itemsPerPage" :options="itemsPerPageOptions" class="mx-1"
                              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                            <span class="text-nowrap"> bản ghi/trang</span>
                          </div>
                          <div>
                            <b-pagination :value="1" :total-rows="totalRows" :per-page="filter.itemsPerPage"
                              class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })" />
                          </div>
                        </div>
                      </template>
                    </vue-good-table>
                  </b-col>
                </b-row>
              </div>
            </b-overlay>
          </b-tab>
          <b-tab title="Xếp loại tốt nghiệp">
            <b-overlay :show="isLoading" rounded="sm">
              <div class="m-2">
                <b-row class="mt-2">
                  <b-col cols="12">
                    <div>
                      <vue-good-table mode="remote" :columns="columns" :rows="dataLists"
                        :pagination-options="paginationOptions" styleClass="vgt-table condensed bordered">
                        <div slot="emptystate" style="text-align: center; font-weight: bold">
                          Không có bản ghi nào !
                        </div>
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field === 'stt'">
                            {{ props.row.originalIndex + 1 }}
                          </span>
                          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                        </template>
                      </vue-good-table>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BOverlay,
  BRow, BFormGroup, BForm, VBTooltip, BTab, BTabs, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'CreditClass',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    BFormGroup,
    BForm,
    vSelect,
    VueGoodTable,
    BTab, BTabs, BFormSelect, BPagination,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      graduation_type_id: undefined,
      selectedItem: undefined,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: '',
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        departmentId: '',
        majorId: '',
        programmeId: '',
        subjectId: '',
        status: '',
        sort: '',
      },
      classId: null,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      isShowGraduationReviewTab: true,
      columnsGraduationGrading: [
        {
          label: 'Mã sinh viên',
          field: 'studentCode',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Họ và tên',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Điểm tốt nghiệp',
          field: 'graduationScore',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Loại hình tốt nghiệp',
          field: 'graduationConditionType',
          thClass: 'text-center',
          sortable: false,
        },
      ],
      columns: [
        {
          label: 'STT',
          field: 'stt',
          width: '5%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Mã sinh viên',
          field: 'student_code',
          sortable: false,
          width: '12%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ và tên',
          field: 'full_name',
          sortable: false,
          width: '18%',
          thClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          sortable: false,
          width: '10%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Hệ số 10',
          field: 'CGPA_10',
          sortable: false,
          width: '8%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Hệ số 4',
          field: 'CGPA_4',
          sortable: false,
          width: '8%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Loại tốt nghiệp',
          field: 'graduation_text',
          sortable: false,
          width: '16%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lý do',
          field: 'reason_fail_graduation',
          width: '23%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      dataLists: 'graduationClass/dataLists',
      classes: 'graduationCondition/classes',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      graduationType: 'graduationCondition/graduationType',
      dataListsGraduationGrading: 'graduationClass/dataListsGraduationGrading',
      totalRows: 'graduationClass/totalRows',
    }),
  },
  async created() {
    this.$socket.emit('register_server_socket', `${this.user.accountId}-${this.user.orgCode}`)
    this.isLoading = true
    try {
      const cUser = getUser()
      this.filter.organizationId = (cUser.orgId ? cUser.orgId : 0)
      await Promise.all([
        this.getTrainingSystems({ organizationId: this.filter.organizationId }),
        this.getDepartments({ organizationId: this.filter.organizationId }),
      ])
      if (this.trainingSystems.length > 0) {
        this.filter.trainingSystemId = this.trainingSystems[0].value
        await this.getCourses(this.filter)
      }
      this.$store.commit('graduationCondition/SET_DATA', { records: [] })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getData: 'graduationClass/getData',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getClasses: 'graduationCondition/getClasses',
      graduationGrading: 'graduationClass/graduationGrading',
      getDataGraduationGrading: 'graduationClass/getDataGraduationGrading',
    }),
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      await this.getCourses(this.filter)
    },
    async onCoursesChange(event) {
      this.filter.courseId = event
      this.filter.courseSemesterId = null
    },
    async onDepartmentChange(event) {
      this.filter.departmentId = event
      this.filter.majorId = null
      await this.getMajors(this.filter)
    },
    async onMajorChange(event) {
      this.filter.majorId = event
      this.filter.programmeId = null
      await this.getClasses(this.filter)
    },
    async onGraduationGrading() {
      const accountId = this.user.accountId
      const orgCode = this.user.orgCode
      const trainingSystemId = this.filter.trainingSystemId
      const courseId = this.filter.courseId
      this.isLoading = true
      try {
        const response = await this.graduationGrading({ trainingSystemId, courseId, accountId, orgCode })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getDataSourcesFromStore()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onTabChange(index) {
      if (index === 0) {
        this.isShowGraduationReviewTab = true
      } else if (index === 1) {
        this.isShowGraduationReviewTab = false
      }
    },
    async onSelect() {
      this.isLoading = true
      try {
        await this.getData({
          class_id: this.classId,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getDataSourcesFromStore()
    },

    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        if(this.isShowGraduationReviewTab && this.filter.courseId != null) {
          await this.getDataGraduationGrading(this.filter)
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
  beforeDestroy() {
    // Hủy kết nối và xóa các lắng nghe sự kiện trước khi component bị hủy
    this.sockets.unsubscribe('response_graduation_grading_from_socket')
  },
  mounted() {
    // Lắng nghe sự kiện từ server socket
    this.sockets.subscribe('response_graduation_grading_from_socket',  async (data) => {
      const dataParse = JSON.parse(data)
      let props = {}
      if (dataParse.status === 1) {
        props = {
          title: dataParse.message,
          icon: 'CheckIcon',
          variant: 'success',
        }
        await this.getDataSourcesFromStore()
      } else {
        props = {
          title: "Có lỗi xảy ra",
          icon: 'XCircleIcon',
          variant: 'danger',
        }
      }
      this.$toast({
        component: ToastificationContent,
        props,
      })
    })
  },
}
</script>
